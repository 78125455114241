import React from "react"
import SEO from '../components/seo';
import Layout from '../containers/layout/layout'
import Hero from '../containers/home-slider/hero'

export default () => (
    <Layout>
        <SEO title="Home"/>
        <Hero/>
    </Layout>
)
 